<template>
  <ul
    v-if="steps > 0"
    class="indicator-list"
    ref="steps"
  >
    <li
      v-for="(index, i) in steps"
      :key="i"
      class="item"
      :class="{'is-active': step >= i }"
    >
      <button
        v-if="step > i"
        v-text="index"
        class="value is-clickable"
        @click="triggerPrevStep()"
      />
      <span v-else class="value">{{ index }}</span>
    </li>
  </ul>
</template>
<script>
export default {
  name: 'register-step-list',

  props: {
    step: {
      type: Number,
      default: 0
    },

    steps: {
      type: Number,
      default: 0
    }
  },

  methods: {
    triggerPrevStep () {
      this.$emit('triggerPrevStep', '')
    }
  }
}
</script>
