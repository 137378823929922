// define a mixin object
import axios from 'axios'

import SimpleSpinner from '@/components/SimpleSpinner.vue'
import ImageContent from '@/layouts/ImageContent.vue'
import StepList from '@/components/partials/RegistrationStepList.vue'
import RegappFeedback from '@/components/partials/RegappFeedback.vue'
import { appSettings } from '@/settings'
import { auth0, login } from '../auth/auth0'
import { mapMutations } from 'vuex'

import * as utils from '../utils.js'

export default {
  components: {
    ImageContent,
    RegappFeedback,
    StepList,
    SimpleSpinner
  },

  computed: {
    termsOfUseCheckboxRequired () {
      return appSettings.features.termsOfUseCheckboxRequired
    },

    isCountryUs () {
      return this.country && this.country.code === 'US'
    },

    nextLabel () {
      if (this.step < this.steps - 1) {
        return 'form.next'
      } else {
        return this.isEdit ? 'form.update' : 'form.register'
      }
    },

    countries () {
      const entries = this.$tm('dropdowns.countries')
      // restricted for signup form
      return utils.sortListByLabel(Object.keys(entries).filter(key => {
        return appSettings.registrationCountries.indexOf(key) >= 0
      }).map(key => ({
        code: key,
        label: entries[key]
      })))
    },

    emailFieldLabel () {
      return this.$t('form.email')
    },
    iAmFieldLabel () {
      return this.$t('iAm')
    },
    passwordFieldLabel () {
      return this.$t('form.password')
    },
    passwordConfirmationFieldLabel () {
      return this.$t('form.passwordConfirmation')
    },
    firstNameFieldLabel () {
      return this.$t('form.firstName')
    },
    recipientFirstNameFieldLabel () {
      return `${this.$t('userRoles.recipient')} ${this.$t('form.firstName')}`
    },
    recipientLastNameFieldLabel () {
      return `${this.$t('userRoles.recipient')} ${this.$t('form.lastName')}`
    },
    recipientRelationshipFieldLabel () {
      return `${this.$t('userRoles.recipient')} ${this.$t('form.relationship')}`
    },
    recipientDateOfBirthFieldLabel () {
      return `${this.$t('userRoles.recipient')} ${this.$t('form.dateOfBirth')}`
    },
    lastNameFieldLabel () {
      return this.$t('form.lastName')
    },
    countryFieldLabel () {
      return this.$t('form.country')
    },
    streetFieldLabel () {
      return this.$t('address.street')
    },
    street1FieldLabel () {
      return this.$t('address.street1')
    },
    institutionFieldLabel () {
      return this.$t('form.clinicName')
    },
    professionsFieldLabel () {
      return this.$t('form.profession')
    },
    zipCodeFieldLabel () {
      return this.$t('address.zipCode')
    },
    stateFieldLabel () {
      return this.$t('address.state')
    },
    cityFieldLabel () {
      return this.$t('address.city')
    },
    deviceSelectorFieldLabel () {
      return this.$t('device.name')
    },
    serialNumberFieldLabel () {
      return this.$t('device.serial')
    },

    countriesProfessionals () {
      const entries = this.$tm('dropdowns.countries')
      // restricted for signup form
      return utils.sortListByLabel(Object.keys(entries).filter(key => {
        return appSettings.registrationCountriesProfessionals.indexOf(key) >= 0
      }).map(key => ({
        code: key,
        label: entries[key]
      })))
    },

    countriesCaregiver () {
      const entries = this.$tm('dropdowns.countries')
      // restricted for signup form
      return utils.sortListByLabel(Object.keys(entries).filter(key => {
        return appSettings.registrationCountriesCaregiver.indexOf(key) >= 0
      }).map(key => ({
        code: key,
        label: entries[key]
      })))
    },

    languages () {
      const entries = appSettings.languages
      return utils.sortListByLabel(Object.keys(entries).map(key => {
        return {
          code: key,
          label: entries[key]
        }
      }))
    }
  },

  methods: {
    ...mapMutations({
      setApiCalling: 'setApiCalling',
      resetUserStore: 'user/resetStore'
    }),

    openLogin () {
      login()
    },

    getSignupUrl () {
      // remove the protocol, e.g. https:// because we still want all other parts of the url, like query/search strings, etc.
      return window.location.hostname + window.location.pathname + window.location.search + window.location.hash
    },

    // this method should only be called after a registration was completed.
    // if any other forms want to use it, add a parameter and adjust responseMessageKey
    setToDone () {
      this.registered = true
      this.responseMessageKey = 'registration.messages.registrationCompleted'
    },

    countryHasFullRegistration () {
      return this.country && appSettings.fullRegistrationCountries.includes(this.country.code)
    },

    prevStep () {
      this.formTransition = 'form-step-right'
      this.step = Math.max(0, this.step - 1)
      this.scrollUp()
    },

    nextStep () {
      this.formTransition = 'form-step-left'
      this.step = Math.min(this.step + 1, this.steps)
      this.scrollUp()
    },

    scrollUp () {
      setTimeout(() => {
        this.$scrollTo('#RegisterForm', 300, {
          offset: -160
        })
      }, 400)
    },

    hasValue (value) {
      return !(value === undefined || value === null || value.trim() === '')
    },

    async submit (e) {
      // if there are more steps go to next page
      if (this.step + 1 < this.steps) {
        return this.nextStep()
      }

      let success = false
      if (this.countryHasFullRegistration()) {
        this.fullRegistration = true
        success = await this.createUserProfileAndUpgrade(this.getRequestObject('full'))
      } else {
        this.fullRegistration = false
        success = await this.createUserProfile(this.getRequestObject('basic'))
      }

      if (success) {
        this.responseMessageKey = `registration.messages.success`
        this.registered = true
        this.scrollUp()
      }
    },

    async submitBasicAccess () {
      await this.createUserProfile(this.getRequestObject('basic'))
    },

    getBasicFormObject () {
      return {
        email: this.form.email,
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        password: this.form.password
      }
    },

    getFilteredValues (obj) {
      const clone = Object.assign({}, obj)
      Object.keys(clone).forEach(i => {
        if (clone[i] === '' || clone[i] === null) delete clone[i]
      })

      return clone
    },

    getOptionValues (translationKey, asObject = false) {
      const entries = this.$tm(translationKey)

      return Object.keys(entries).map(key => {
        return asObject ? {
          code: key,
          label: entries[key]
        } : entries[key]
      })
    },

    async createUserProfile (obj) {
      const URL = process.env.VUE_APP_API_BASE + '/users'
      var result = await this.sendRequest('post', URL, obj, false, false)
      return result
    },

    async createUserProfileAndUpgrade (obj) {
      const URL = process.env.VUE_APP_API_BASE + '/users/upgrade'
      var result = await this.sendRequest('post', URL, obj, false, false)
      return result
    },

    async putUserProfile (obj, isCombinedRequest) {
      const URL = process.env.VUE_APP_API_BASE + `/users/${auth0.user.value.sub}`
      var result = await this.sendRequest('put', URL, obj, true, isCombinedRequest)
      return result
    },

    patchUserProfile (obj, isCombinedRequest) {
      const URL = process.env.VUE_APP_API_BASE + `/users/${auth0.user.value.sub}`
      return this.sendRequest('patch', URL, obj, true, isCombinedRequest)
    },

    postUpgrade (obj) {
      const URL = process.env.VUE_APP_API_BASE + `/users/${auth0.user.value.sub}/upgrades`
      return this.sendRequest('post', URL, obj, true, false)
    },

    async sendRequest (method, url, data, isAuthenticated, isCombinedRequest) {
      let isSuccess = false
      let headers = {}

      if (isAuthenticated) {
        let token = await this.$auth0.getAccessTokenSilently()
        headers = { 'Authorization': `Bearer ${token}` }
      }

      this.setApiCalling(true)
      try {
        await axios({
          method: method,
          url: url,
          data: this.getFilteredValues(data),
          headers: headers
        })
        if (!isCombinedRequest) {
          this.setApiCalling(false)
        }
        isSuccess = true
      } catch (error) {
        this.setApiCalling(false)
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
          this.$refs.validationErrorMessage.open()
        } else {
          this.resetUserStore()
          this.$refs.modalError.open()
        }
      }
      return isSuccess
    }
  }
}
