<template>
  <div class="RegFeedback Headline is-size-5">
    <slot name="body" />
    <slot name="footer" />
  </div>
</template>

<script>
export default {
  name: 'RegappFeedback'
}
</script>
