<template>
    <label class="form-input-label" :class="{ 'is-error': errors.errors[fieldName] }">
        <small class="form-input-title d-block" :class="{ 'is-error': errors.length > 0 }">{{ fieldLabel }}</small>
        <Field
            :name="fieldName"
            :label="fieldLabel"
            :rules="rules"
            v-slot="{ field }">
            <div class="input-password-wrapper">
                <input
                v-bind="field"
                class="form-input-field d-block"
                :type="showPassword?'text':'password'" />
                <label
                :class="{ 'bi-eye': !showPassword, 'bi-eye-slash': showPassword }"
                class="input-password-label"
                @click="togglePassword" />
            </div>
          </Field>
        <small v-if="errors.errors[fieldName]" class="form-input-error" :name="fieldName" v-html="formatErrorMessage(errors.errors[fieldName])"></small>
      </label>
</template>

<script>
import { Field } from 'vee-validate'
import formFields from '@/mixins/formFields'

export default {
    name: 'InputPasswordComponent',

    components: {
    Field
    },

    data () {
        return {
            showPassword: false
        }
    },

    methods: {
        togglePassword () {
            this.showPassword = !this.showPassword
        },
        formatErrorMessage (error) {
           return error.replaceAll('|', '<br/>')
        }
    },

    mixins: [
        formFields
    ],

    props: {
        errors: {
            type: Object,
            required: true
        },
        rules: {
            type: String,
            required: true,
            default: 'required'
        },
        fieldName: {
            type: String,
            required: true
        },
        fieldLabel: {
            type: String,
            required: true
        }
    }
}
</script>
<style lang="scss" scoped>
@import '../../styles/colors';
@import '../../styles/media-queries';

.form-input-label {
    position: relative;
    display: block;

    &.is-error {
        .form-input-title {
            color: $color-red-500;
        }

        .form-input-field {
            border-color: $color-red-500;
        }
    }
}

.form-input-title {
    color: $color-grey-500;
    margin-bottom: 5px;
    font-size: 0.8em;
}

.form-input-field {
    border: 1px solid $color-grey-300;
    background-color: $white;
    font-size: 1.3em;
    line-height: 1.5em;
    padding: 0.5em 1em;
    border-radius: 4px;
    width: 100%;

    @include media('<=sm') {
      margin-top: 0.2em;
      padding: 0.4em 0.3em;
    }
}

.form-input-error {
    color: $color-red-500;
    font-size: 12px;
    margin: 4px 0 0;
  }

.input-password-label {
  position: absolute;
  right: 16px;
  top: 13px;
  font-size: 1.2em;
}

.input-password-wrapper {
  position: relative;
}
</style>
